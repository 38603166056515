<!--此润滑保养是瑞祥版本-->

<template>
  <div class="alarm">
    <div class="alarm-top">
      <van-nav-bar
        title="润滑保养"
        left-arrow
        :border="false"
        @click-left="onClickLeft"
      >
      </van-nav-bar>
      <div ref="filterBoxWrapper" class="filter-box-wrapper">
        <van-search
          v-model.trim="query.equipId"
          class="search-bar"
          placeholder="请输入设备代号或设备名称查询"
          @search="onSearch"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div class="alarm-search">
          <div class="date-time-box">
            <date-time
              v-model="searchTime"
              type="date"
              title="选择起止日期"
              @input="lookfor"
            />
          </div>
        </div>
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.isActive || item.text }]"
            @click="selectCon(item.popupKey)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="alarm-total">共{{ total }}条结果</div>
        </div>
      </div>
    </div>
    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="alarm-list"
      @refresh="pullGetAgain"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText || '没有更多了'"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div
          v-for="item in list"
          :key="item.id"
          class="alarm-item"
          @click="gotoDetail(item)"
        >
          <div class="alarm-header">
            <div class="alarm-title">
              <div class="alarm-name-box">
                {{ item.equipName }}（{{ item.equipMachineNo }}）
              </div>
              <div v-if="item.maintenanTypeName" class="status-up-box">
                <div class="status-box">
                  <img src="@/assets/images/typeUi.png" />
                  <span>{{ item.maintenanTypeName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="alarm-time">
            <img class="second-icon" src="@/assets/images/dateUi.png" />
            <span class="date-text">计划保养日期:{{ item.executionDay }}</span>
          </div>

          <div
            class="alarm-picture"
            :style="{ top: item.maintenanTypeName ? '31.09px' : '18px' }"
          >
            <img
              :src="
                require(`@/assets/images/equipment/${statusIconSwitch(
                  item.status
                )}.png`)
              "
            />
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <SelectPopupGrade
      v-model="popupVisibleObj[1].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[1].title"
      :list.sync="popupVisibleObj[1].list"
      :visible="popupVisibleObj[1].visiable"
      @change="v => popupGradeChange(v, 0, 1)"
      @close="popupVisibleObj[1].visiable = false"
    />
    <SelectPopupGrade
      v-model="popupVisibleObj[2].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[2].title"
      :list.sync="popupVisibleObj[2].list"
      :visible="popupVisibleObj[2].visiable"
      @change="v => popupGradeChange(v, 1, 2)"
      @close="popupVisibleObj[2].visiable = false"
    />
  </div>
</template>

<script>
import { getDeviceLubImplementList, getBasicParams } from "./api";
import { mapMutations, mapState } from "vuex";

export default {
  name: "DeviceServeList",
  data() {
    return {
      //保养类型与保养状态分类查询
      filterList: [
        {
          name: "保养类型",
          type: "select",
          text: "",
          popupKey: 1,
          isActive: false
        },
        {
          name: "保养状态",
          type: "select",
          popupKey: 2,
          text: "",
          isActive: false
        }
      ],
      popupVisibleObj: {
        1: {
          visiable: false,
          title: "保养类型",
          value: "",
          list: []
        },
        2: {
          visiable: false,
          title: "保养状态",
          value: "",
          list: []
        }
      },
      list: [],
      // 查询条件
      query: {
        orgCode: "",
        equipId: "",
        size: 10,
        page: 1,
        status: "",
        maintenanType: ""
      },
      searchTime: {
        start: "",
        end: ""
      },
      total: 0,
      listStauts: {
        loading: true,
        finished: false
      },
      refreshing: false,
      finishedText: "",
      loading: false,
      finished: false,
      asyncOptions: {
        maintenanType: []
      }
    };
  },
  watch: {
    $route(to, from) {
      if (to.params.refresh) {
        this.onSearch();
      }
    }
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth,
      needRefreshList: state => state.device.needRefreshList,
      userInfo: state => state.login.userInfo
    }),
    statusIconSwitch() {
      return status => {
        const obj = {
          0: "executing",
          1: "toBeReviewed",
          2: "yiwancheng",
          3: "obsolete",
          4: "expired",
          5: "yqpendingApproval",
          6: "pendingReview"
        };
        return obj[status];
      };
    }
  },
  //进入页面时
  created() {
    this.query.orgCode = this.userInfo.orgCode;
    this.getOptions("lubMainType", "maintenanType"); //保养类型
    this.onSearch();
  },
  activated() {
    // 去查询列表
    this.$nextTick(() => {
      if (this.$refs.vanList && this.$refs.vanList.$el) {
        this.$refs.vanList.$el.scrollTo(0, this.scrollHeight || 0);
      }
    });
    if (this.needRefreshList) {
      this.onSearch();
      this.setNeedRefreshList({ value: false });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.scrollTo();
    next();
  },
  methods: {
    // 根据基础编码获取基础数据
    getOptions(codeId, prop) {
      getBasicParams(codeId).then(res => {
        this.asyncOptions[prop] = res.unDelete;
      });
    },
    //点击保养类型与保养状态
    async selectCon(popupKey) {
      let data = [];
      if (popupKey === 1) {
        data = this.asyncOptions.maintenanType;
      } else {
        data = [
          { label: "执行中", value: "0" },
          { label: "待审核", value: "1" },
          { label: "已完成", value: "2" },
          { label: "已作废", value: "3" },
          { label: "已过期", value: "4" },
          { label: "待延期审批", value: "5" },
          { label: "待延期审核", value: "6" }
        ];
      }
      this.popupVisibleObj[popupKey].list = data;
      this.popupVisibleObj[popupKey].visiable = true;
    },
    //选择类型和状态后
    popupGradeChange(v, filterListIdx, popupKey) {
      const vObj = v.length > 0 ? v[0] : {};
      this.popupVisibleObj[popupKey].value = vObj.value || "";
      this.filterList[filterListIdx].text = vObj.label || "";
      if (popupKey === 1) {
        this.query.maintenanType = vObj.value || "";
      } else {
        this.query.status = vObj.value || "";
      }
      this.onSearch();
    },
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    onSearch() {
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    //跳转页面
    gotoDetail(item) {
      if (!this.btnAuth.upload) {
        this.$toast.fail("暂无上传记录权限");
        return false;
      }
      this.$router.push({
        name: "deviceLubImplementEdit",
        params: {
          type: item.status === "0" ? "edit" : "detail",
          id: item.execId
        }
      });
    },
    // 日期选择
    lookfor(val) {
      if (val.start && val.end) {
        this.query.startDate = val.start;
        this.query.endDate = val.end;
        this.onSearch();
      } else if (!val.start && !val.end) {
        this.query.endDate = "";
        this.query.startDate = "";
        this.onSearch();
      }
    },
    //返回主页
    onClickLeft() {
      // history.go(-1);
      this.$router.push({ path: "/" });
    },
    //下拉刷新
    pullGetAgain() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.getList();
    },
    getList() {
      getDeviceLubImplementList(this.query).then(res => {
        this.loading = false;
        if (res.list && res.list.length) {
          this.total = res.total;
          this.list = this.list.concat(res.list);
          this.query.page++;
        } else {
          //这里是否可以改(!res.list.length)
          if (!this.list.length) {
            this.finishedText = "没有更多了";
          } else {
            this.finishedText = "";
          }
          this.finished = true;
        }
      });
    },
    scrollTo() {
      this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    }
  }
};
</script>

<style scoped lang="scss">
.alarm {
  position: relative;
  text-align: left;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .dept-item {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    font-size: 14px;

    &.active {
      .dept-name {
        color: $--color-primary;
      }
    }

    &__left {
      width: 50%;
      display: flex;
      justify-content: space-between;
    }

    .dept-name {
      display: inline-block;
      color: #2e2e4d;
      max-width: 100px;
    }

    .iconfont-danxuan-weixuan {
      color: #d4dbe3;
    }
  }
}

.alarm-top {
  position: relative;

  .van-nav-bar {
    z-index: 12;
  }

  .search-bar {
    padding-bottom: 0;
  }

  .filter-box-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s;
    z-index: 10;
  }

  .department-box {
    padding: 0 0 0 16px;
    display: flex;
    flex-direction: row;
    height: 32px;

    .department-list {
      height: 32px;
      font-size: 12px;
      color: #3b4664;
      flex: 1;
      overflow-x: scroll;
      white-space: nowrap;
      display: flex;
      align-items: center;

      &::-webkit-scrollbar {
        display: none;
      }

      .department-item {
        display: inline-block;
        padding: 10px 12px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.active {
          color: $--color-primary;
        }
      }
    }

    .department-filter {
      position: relative;
      width: 56px;
      min-width: 56px;
      font-size: 16px;
      color: $--color-primary;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconfont {
        margin-left: 12px;
      }

      &::before {
        position: absolute;
        content: "";
        left: 12px;
        top: 50%;
        width: 1px;
        height: 16px;
        background-color: #c7c9d0;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  .filter-box {
    position: relative;
    height: 40px;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid #c7c9d0;

    &::-webkit-scrollbar {
      display: none;
    }

    .filter-item {
      position: absolute;
      top: 12px;
      left: 0;
      display: inline-block;
      padding: 4px 6px;
      padding-left: 0;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3b4664;

      &:first-child {
        margin-left: 70.97px;
      }

      &:last-child {
        margin-left: 97.32px;
      }

      &.select {
        position: relative;
        padding-right: 18px;

        &::after {
          position: absolute;
          content: "";
          right: 6px;
          top: 8px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }

        &.active {
          &::after {
            border-top-color: $--color-primary;
          }
        }
      }

      &.active {
        // background-color: #dcecff;
        color: $--color-primary;
      }
    }
  }

  .search-condition {
    display: flex;
    // margin: 1vw 5.6vw 3vw 5.6vw;
    justify-content: center;

    .loading {
      display: inline-block;
    }

    .van-loading__spinner {
      width: 4vw;
      height: 4vw;
    }

    .alarm-total {
      color: #8c8f97;
      text-align: center;
      font-size: 11.15px;
      padding: 11px 0 0;
      height: 17.55px;
      line-height: 17.55px;
      font-weight: 400;
    }

    .condition-switch {
      color: $--color-icon;

      i {
        font-size: 5vw;
      }
    }
  }
}

.alarm-search {
  // position: relative;
  margin: 0;
  // height: 14vh;
  .date-time-box {
    padding: 12px 16px 0 16px;
    background-color: #fff;

    .datetime {
      position: relative;
      margin: 0;
    }
  }
}

.alarm-list {
  /*height: 68vh;*/
  padding-top: 165px;
  flex: 1;
  overflow-y: auto;
  z-index: 9;
}

.alarm-item {
  padding: 11px 17px 13px 16px;
  border-bottom: 8px solid #eceef2;
  font-size: 4vw;
  position: relative;

  .alarm-header {
    display: flex;

    .alarm-title {
      flex: 3;
      max-width: 70vw;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.1;
      }

      color: #3b4664;

      .alarm-name-box {
        margin: 0 0 9.2px;
        height: 19.07px;
        line-height: 19.07px;
        color: #2e2e4d;
        font-size: 14px;
        font-weight: 500;
        font-family: PingFangSC-Regular, PingFang SC;

        .iconfont-baojingyuzhi {
          margin: 0 4px 0 0;
          vertical-align: sub;
        }

        .alarm-name {
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #3b4664;
          line-height: 20px;
        }
      }

      .status-up-box {
        display: flex;
        flex-direction: row;

        .status-box {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 16.35px;
          line-height: 16.35px;
          margin: 0 0 6px;
          font-size: 12.11px;

          img {
            height: 15.23px;
            width: 15.23px;
            margin-right: 1.0001px;
          }

          span {
            display: inline-block;
            color: #a0a2a6;
          }

          .equipment-name {
            font-size: 12px;
            font-weight: 400;
            color: #3b4664;
            line-height: 17px;
            margin: 0 20px 0 0;
          }

          .status-color-box {
            display: inline-block;
            font-size: 11px;
            font-weight: 400;
            color: #8b4fee;
            line-height: 16px;
            padding: 0 6px 0;
            margin: 0 8px 0 0;
            background: #ede2ff;
            border: 1px solid #8b4fee;
            border-radius: 3px;
          }

          .type-color {
            color: #00b6d6;
            background: #d8feff;
            border: 1px solid #00b6d6;
          }
        }
      }

      .second-icon {
        margin: 0 4px 0 0;
        vertical-align: middle;
      }
    }

    .alarm-status {
      flex: 1;
      /*font-size: 100vw;*/
      text-align: right;
      padding: 7px 0 0;

      .trouble-status-icon {
        display: inline-block;
        text-align: center;
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 40px;

        &::after {
          display: inline-block;
          width: inherit;
          height: inherit;
        }
      }
    }
  }

  .alarm-time {
    color: #a0a2a6;
    margin-top: -3px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      height: 15.23px;
      width: 15.23px;
      margin-right: 1.0001px;
    }

    .second-icon {
      margin: 0 4px 0 0;
      vertical-align: middle;
    }

    .date-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin: 0 20px 0 0;
    }
  }

  .alarm-picture {
    position: absolute;
    top: 31.09px;
    right: 14.96px;

    img {
      height: 40.11px;
      width: 45.1px;
    }
  }

  .alarm-list ::v-deep.van-pull-refresh {
    padding-top: 5vm;
  }
}
</style>
